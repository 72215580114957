import React from 'react';
import { Error } from '@material-ui/icons';
import {
    Typography,
    CircularProgress,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { DASHBOARD_METRICS } from '../../lib/graphql/queries/dashboard';
import { useQuery } from '@apollo/client';
import {
    formatMoney,
    GridContainer,
    GridItem,
    SubmissionStatus,
    useAuth,
} from '@elevatormedia/duffel-bag';
import { ROUTES } from '../../config/Nav';
import { Refresh } from 'mdi-material-ui';
import TopPageViewStats from '../Analytics/TopPageViewStats';
import useStyles from './styles';
import { GridItemPropTypes } from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import { DashboardMetricBase } from 'types/dashboard';
import { BaseRouteConfig, RoutePermission } from 'types/nav';
import { ContentSpacer, DashboardCardAdornment } from 'atoms';
import { DashboardMetricsCard } from 'molecules';

const Dashboard = () => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { permissionsMap } = useAuth();

    const { loading, error, data, refetch, networkStatus } = useQuery(DASHBOARD_METRICS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const canAccessData = (ROUTES.dashboard.permissions as RoutePermission[])
        .map((permission) => permissionsMap[permission.type][permission.operation])
        .includes(true);

    const dashboardMetrics =
        data && Object.keys(data).length > 0
            ? (() => {
                  const metrics: DashboardMetricBase[] = [];

                  if (data.submissionTotalsByStatus) {
                      const submissionStats = {
                          ...data.submissionTotalsByStatus,
                      };

                      // Remove GraphQL data
                      delete submissionStats.__typename;

                      const metricsList = Object.keys(submissionStats)
                          .slice(0, 3)
                          .map((metric: SubmissionStatus) => {
                              const base: DashboardMetricBase = {
                                  cardTitle: metric,
                                  cardMetricText: submissionStats[metric],
                                  navigateTo: (
                                      ROUTES.submissions.allSubmissions as BaseRouteConfig
                                  ).to,
                                  queryParams: {
                                      status: metric,
                                  },
                                  endAdornment: (
                                      <DashboardCardAdornment adornment={metric} />
                                  ),
                              };

                              return base;
                          });

                      metrics.push(...metricsList);
                  }

                  const monthlySubmissionTotal = data.monthlySubmissionStats
                      .map((submission: any) => {
                          return submission['count'];
                      })
                      .reduce(
                          (previousValue: any, currentValue: any) =>
                              previousValue + currentValue,
                          0,
                      );

                  metrics.unshift({
                      highContrast: false,
                      navigateTo: (ROUTES.submissions.allSubmissions as BaseRouteConfig)
                          .to,
                      cardMetricText: monthlySubmissionTotal,
                      cardTitle: 'Submissions',
                      queryParams: {},
                      endAdornment: (
                          <DashboardCardAdornment
                              adornment={
                                  data.submissionsChangeStats >= 0
                                      ? 'increase'
                                      : 'decrease'
                              }
                              text={
                                  parseFloat(
                                      data.submissionsChangeStats.toFixed(2).toString(),
                                  ) + '%'
                              }
                          />
                      ),
                  });

                  if (data.monthlySubmissionStats) {
                      const monthlyStats = {
                          ...data.monthlySubmissionStats,
                      };

                      const checks = ['Standard', 'Sponsored', 'Promoted'];

                      const metricsList = Object.values(monthlyStats)
                          .filter((metric: any) => {
                              const includesValues = checks
                                  .map((value) => metric['type'].includes(value))
                                  .includes(true);

                              return (
                                  includesValues && !metric['type'].includes('Featured')
                              );
                          })
                          .map((metric: any) => {
                              const base: DashboardMetricBase = {
                                  cardTitle: metric['type'].split(' ')[0],
                                  cardMetricText: metric['count'],
                                  navigateTo: (
                                      ROUTES.submissions.allSubmissions as BaseRouteConfig
                                  ).to,
                                  queryParams: {},
                                  endAdornment: (
                                      <DashboardCardAdornment
                                          adornment={'Rejected'}
                                          text={
                                              metric['count'] !== 0
                                                  ? parseFloat(
                                                        (
                                                            metric['declined'] /
                                                            metric['count']
                                                        )
                                                            .toFixed(2)
                                                            .toString(),
                                                    ) + '%'
                                                  : 'n/a'
                                          }
                                      />
                                  ),
                              };

                              return base;
                          });

                      metrics.push(...metricsList);
                  }

                  metrics.push({
                      highContrast: true,
                      navigateTo: null,
                      cardMetricText: formatMoney(data.monthlyEarnings),
                      cardTitle: 'Monthly Revenue',
                      queryParams: {},
                      endAdornment: (
                          <DashboardCardAdornment
                              adornment={
                                  data.submissionsChangeStats >= 0
                                      ? 'increase'
                                      : 'decrease'
                              }
                              text={
                                  data.monthlyEarningsChange
                                      ? parseFloat(
                                            data.monthlyEarningsChange
                                                .toFixed(2)
                                                .toString(),
                                        ) + '%'
                                      : 'n/a'
                              }
                          />
                      ),
                  });
                  metrics.push({
                      highContrast: false,
                      navigateTo: (ROUTES.users.all as BaseRouteConfig).to,
                      cardMetricText: data.newUserStats,
                      cardTitle: 'New Users',
                      queryParams: {},
                  });
                  metrics.push({
                      highContrast: false,
                      navigateTo: (ROUTES.users.all as BaseRouteConfig).to,
                      cardMetricText: data.totalUsers,
                      cardTitle: 'Users',
                      queryParams: {},
                  });
                  return metrics;
              })()
            : [];

    const renderEmptyState = () => {
        return (
            <GridContainer
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                style={{ flexGrow: 1, height: '70vh' }}
            >
                <GridItem
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Error
                        color={'disabled'}
                        fontSize={'large'}
                        style={{ fontSize: 200 }}
                    />
                    <Typography variant={'h6'} color={'textSecondary'}>
                        You do not have all of the necessary permissions to view this
                        data.
                    </Typography>
                </GridItem>
            </GridContainer>
        );
    };

    /**
     * Renders Dashboard Metric Cards which are navigatible via click interactions.
     */
    const renderOverviewCards = () => {
        const sizePropsRegular: Partial<GridItemPropTypes> = {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 3,
        };
        const sizePropsLarge: Partial<GridItemPropTypes> = {
            xs: 12,
            sm: 12,
            md: 4,
            lg: 3,
        };

        const checks = ['Monthly', 'Submission'];

        return dashboardMetrics.map((metricData, index) => {
            const sizeProps = checks
                .map((value) => metricData.cardTitle.includes(value))
                .includes(true)
                ? sizePropsLarge
                : sizePropsRegular;

            return (
                <GridItem
                    key={index}
                    className={classes.metricsGridItem}
                    paddingY={mobile ? 0.5 : 1}
                    paddingX={mobile ? 0.5 : 1}
                    {...sizeProps}
                >
                    <DashboardMetricsCard
                        highContrast={metricData.highContrast}
                        cardTitle={metricData.cardTitle}
                        cardMetricText={metricData.cardMetricText}
                        navigateTo={metricData.navigateTo}
                        queryParams={metricData.queryParams}
                        endAdornment={metricData.endAdornment}
                    />
                </GridItem>
            );
        });
    };

    /**
     * Renders a text SubTitle to be used on different section bodies in the dasboard
     */
    const renderSectionHeader = (textBody: string) => {
        return (
            <Typography gutterBottom variant={'subtitle1'} color={'textPrimary'}>
                {textBody}
            </Typography>
        );
    };

    const renderDashboard = () => {
        return (
            <GridContainer direction={'column'} className={classes.root}>
                <GridItem>
                    <GridContainer
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <GridItem>{renderSectionHeader('Overview')}</GridItem>
                        <GridItem>
                            <IconButton onClick={() => refetch()}>
                                <Refresh color={'primary'} />
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                    <GridContainer direction={'row'} spacing={3}>
                        {renderOverviewCards()}
                    </GridContainer>
                    <GridContainer
                        className={classes.topPageStatsContainer}
                        direction={'row'}
                        spacing={3}
                    >
                        <TopPageViewStats dashboard={true} />
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    };

    const renderScreenContent = () => {
        if (loading || networkStatus === 4) {
            return (
                <GridContainer
                    style={{ height: '90vh' }}
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <GridItem>
                        <CircularProgress size={40} />
                    </GridItem>
                </GridContainer>
            );
        } else if (error && !data && !canAccessData) {
            return renderEmptyState();
        } else {
            return renderDashboard();
        }
    };

    return <ContentSpacer>{renderScreenContent()}</ContentSpacer>;
};

export default Dashboard;
