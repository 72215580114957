import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        metricsGridItem: {
            flexGrow: 1,
        },

        // Card Styles
        submissionsNavigatorCard: {
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
            marginTop: theme.spacing(3),
        },

        topPageStatsContainer: {
            marginTop: theme.spacing(5),
        },
    }),
);

export default useStyles;
