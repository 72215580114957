import gql from 'graphql-tag';

const DASHBOARD_METRICS = gql`
    query {
        monthlyEarnings
        monthlyEarningsChange
        monthlySubmissionStats {
            type
            count
            declined
        }
        submissionTotalsByStatus {
            Review
            Approval
            Approved
            Completed
            Rejected
        }
        submissionsChangeStats
        totalUsers
        newUserStats
    }
`;

export { DASHBOARD_METRICS };
